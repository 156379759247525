<template>
  <v-dialog v-model="visible" max-height="500" max-width="700" >
    <v-card>
      <v-toolbar dense color="gray" dark>
        <v-toolbar-title class="title" justify="center">{{
          formTitle
        }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="closeModal">
          <v-icon>mdi mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="form-content">
        <v-form ref="form" v-model="valid" @submit.prevent="submitForm">
          <v-row>
            <v-col cols="5">
              <label for="name:">Abkürzung</label>
              <v-text-field
                class="textFieldOne"
                outlined
                v-model="baumart.abbreviation"
                :rules="rules.abbreviation"
                @input="abbreviationInput"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <label for="name:">Deutsch</label>
              <v-text-field
                class="textFieldTwo"
                outlined
                v-model="baumart.germanName"
                :rules="rules.germanName"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="5">
              <label for="name:">Latein</label>
              <v-text-field
                class="textFieldThree"
                outlined
                v-model="baumart.latinName"
                :rules="rules.latinName"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-btn
              type="submit"
              class="emptyForm"
              style="margin-left:115px; max-width:360px; text-color:black;"
              :loading="isLoading"
              color="primary"
              :disabled="!valid || isSubmitting"
              >Erstellen</v-btn
            >
            <v-btn
              type="button"
              class="emptyForm"
              style="margin-left:25px;"
              :loading="isLoading"
              color="gray"
              :disabled="!valid || isSubmitting"
              @click="closeModal"
              >Abbrechen</v-btn
            >
          </v-row>
        </v-form>
        <v-alert
          type="success"
          elevation="20"
          v-show="showAlert"
          :type="alertColor"
          class="alert"
        >
          {{ alertText }}
        </v-alert>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";

export default {
  props: {
    baumart: {
      type: Object,
      default: () => ({
        id: null,
        abbreviation: "",
        germanName: "",
        latinName: ""
      })
    },
    visible: Boolean,
    itemList: Array
  },
  data() {
    return {
      isLoading: false,
      showAlert: false,
      alertText: "",
      alertColor: "",
      duplicateAbbreviationError: false,
      rules: {
        abbreviation: [
          v => {
              if (!v) return true; // Allow empty input
              if (v.length <= 4) {
                return true;
              } else {
                return "Die Abkürzung darf höchstens 4 Zeichen umfassen";
              }
            },
            v => {
              if (!v) return true; // Allow empty input
              if (v.length >= 4) {
                return true;
              } else {
                return "Die Abkürzung muss mindestens 4 Zeichen enthalten";
              }
            }
        ],
        germanName: [v => !v || !!v || "Deutscher Name ist erforderlich"],
        latinName: [v => !v || !!v || "Lateinischer Name ist erforderlich"]
      },
      valid: false
    };
  },
  computed: {
    formTitle() {
      return this.baumart && this.baumart.id
        ? "Baumart bearbeiten"
        : "Neu Baumart";
    },
    // validForm() {
    //   return (
    //     !!this.baumart.germanName &&
    //     !!this.baumart.latinName &&
    //     !!this.baumart.abbreviation
    //   );
    // }
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    abbreviationInput() {
      const inputValue = this.baumart.abbreviation;

      // Check if the abbreviation already exists
      const isDuplicate = this.itemList.some(
        item => item.abbreviation === inputValue
      );

      if (inputValue.length === 4 && !isDuplicate) {
        this.disableErstellen = false;
        this.duplicateAbbreviationError = false;
      } else {
        this.disableErstellen = true;
        this.duplicateAbbreviationError = isDuplicate;
      }
    },
    submitForm() {
      this.isLoading = true;
      this.isSubmitting = true;


      // Clear duplicateAbbreviationError when submitting the form
      this.duplicateAbbreviationError = false;

      const requestBody = {
        abbreviation: this.baumart.abbreviation,
        germanName: this.baumart.germanName,
        latinName: this.baumart.latinName
      };

      if (!this.baumart.id && !this.duplicateAbbreviationError) {
        axios
          .post(
            "https://tilia.rrota.org/api/TreeType/Create",
            requestBody
          )
          .then(response => {
            this.$emit('item-created', true);

            this.isLoading = false;
            if (Array.isArray(this.itemList)) {
              this.itemList.push(response.data);
            } else {
              this.itemList = [response.data];
            }
            if (response.status === 200) {
              this.showAlert = true;
              this.alertColor = "success";
              this.alertText = "Das neue Baumart wurde erfolgreich erstellt";
              setTimeout(() => {
                this.closeModal();
                this.showAlert = false;
                this.$refs.form.reset();
                this.valid = true;
                this.isSubmitting = false;

              }, 2000);
            }
          })
          .catch(error => {
            console.log(error);
            this.isLoading = false;
            this.showAlert = true;
            this.alertColor = "error";
            this.alertText = "Fehler beim Erstellen der Baumart";
            this.isSubmitting = false;

            setTimeout(() => {
              this.showAlert = false;
            }, 2000);
          });
      }
    }
  }
};
</script>

<style>
.alert {
  position: fixed !important;
  width: 100% !important;
  max-width: 784px !important;
  left: 50% !important;
  top: 50% !important;
  transform: translate(-50%, -50%) !important;
  padding: 40px 100px !important;
}
.form-content {
  margin-top: 60px;
}
</style>
